@import "https://fonts.googleapis.com/css?family=Archivo:900,800,100,400,700,italic";
@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
}

button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}

a {
  text-decoration: none;
}

.landing-page {
  background-color: #181719;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.landing-page .div {
  background-color: #181719;
  width: 1920px;
  height: 8925px;
  position: relative;
  overflow: hidden;
}

.landing-page .overlap {
  width: 2008px;
  height: 8951px;
  position: absolute;
  top: -26px;
  left: 0;
}

.landing-page .main-bg {
  width: 1920px;
  height: 7629px;
  position: absolute;
  top: 26px;
  left: 0;
}

.landing-page .footer {
  background-color: #0000003d;
  width: 1920px;
  height: 480px;
  position: absolute;
  top: 8471px;
  left: 0;
}

.landing-page .subfooter {
  width: 1926px;
  height: 60px;
  position: absolute;
  top: 420px;
  left: 0;
}

.landing-page .overlap-group {
  background-color: #0000007a;
  width: 1920px;
  height: 60px;
  position: relative;
}

.landing-page .cookies-policy-terms {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: right;
  width: 464px;
  height: 16px;
  font-family: Archivo, Helvetica;
  font-size: 15px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 1216px;
}

.landing-page .text-wrapper {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 464px;
  height: 16px;
  font-family: Archivo, Helvetica;
  font-size: 15px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 728px;
}

.landing-page .p {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 464px;
  height: 16px;
  font-family: Archivo, Helvetica;
  font-size: 15px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 240px;
}

.landing-page .group {
  width: 466px;
  height: 134px;
  position: absolute;
  top: 130px;
  left: 1216px;
}

.landing-page .rimani-aggiornato {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 464px;
  height: 20px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .line {
  object-fit: cover;
  width: 342px;
  height: 1px;
  position: absolute;
  top: 51px;
  left: 0;
}

.landing-page .linkedin {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 86px;
  left: 0;
}

.landing-page .facebook {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 86px;
  left: 98px;
}

.landing-page .instagram {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 86px;
  left: 196px;
}

.landing-page .group-2 {
  width: 474px;
  height: 217px;
  position: absolute;
  top: 130px;
  left: 726px;
}

.landing-page .scopri-di-pi {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 464px;
  height: 20px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 2px;
}

.landing-page .team {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 222px;
  height: 48px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 73px;
  left: 0;
}

.landing-page .white-paper {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 222px;
  height: 48px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 121px;
  left: 0;
}

.landing-page .roadmap {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 222px;
  height: 48px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 169px;
  left: 0;
}

.landing-page .img {
  object-fit: cover;
  width: 342px;
  height: 1px;
  position: absolute;
  top: 51px;
  left: 2px;
}

.landing-page .crowdmoovi-logo {
  width: 342px;
  height: 50px;
  position: absolute;
  top: 202px;
  left: 240px;
}

.landing-page .CLUB {
  width: 1924px;
  height: 509px;
  position: absolute;
  top: 7737px;
  left: 2px;
}

.landing-page .invest {
  width: 686px;
  height: 72px;
  position: absolute;
  top: 437px;
  left: 614px;
}

.landing-page .overlap-group-2 {
  border-radius: 40.5px;
  width: 684px;
  height: 72px;
  position: relative;
}

.landing-page .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  width: 313px;
  height: 26px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 23px;
  left: 62px;
}

.landing-page .vector {
  width: 14px;
  height: 18px;
  position: absolute;
  top: 27px;
  left: 600px;
}

.landing-page .text-wrapper-3 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 596px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  line-height: 28.8px;
  position: absolute;
  top: 305px;
  left: 660px;
}

.landing-page .overlap-2 {
  width: 1916px;
  height: 305px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.landing-page .text-wrapper-4 {
  -webkit-text-stroke: 1px transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  background-image: linear-gradient(#f7941d 0%, #672d86 100%);
  -webkit-background-clip: text;
  width: 1916px;
  height: 305px;
  font-family: Archivo, Helvetica;
  font-size: 280px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .text-wrapper-5 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(#672d86 0%, #f7941d 100%) text;
  width: 714px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 130px;
  left: 598px;
  -webkit-background-clip: text !important;
}

.landing-page .text-wrapper-6 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 1199px;
  height: 39px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 91px;
  left: 355px;
}

.landing-page .OUR-NFT {
  width: 1928px;
  height: 2294px;
  position: absolute;
  top: 5169px;
  left: 4px;
}

.landing-page .overlap-3 {
  width: 1442px;
  height: 908px;
  position: absolute;
  top: 1386px;
  left: 236px;
}

.landing-page .overlap-group-wrapper {
  width: 466px;
  height: 72px;
  position: absolute;
  top: 836px;
  left: 488px;
}

.landing-page .overlap-group-3 {
  background: linear-gradient(#672d86 0% 100%);
  border-radius: 40.5px;
  width: 464px;
  height: 72px;
  position: relative;
  box-shadow: 0 0 24px 12px #672d8699;
}

.landing-page .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 39px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 17px;
  left: 57px;
}

.landing-page .vector-2 {
  width: 10px;
  height: 18px;
  position: absolute;
  top: 27px;
  left: 407px;
}

.landing-page .element {
  width: 466px;
  height: 563px;
  position: absolute;
  top: 109px;
  left: 976px;
}

.landing-page .overlap-4 {
  width: 464px;
  height: 563px;
  position: relative;
}

.landing-page .rectangle {
  background: linear-gradient(#ffffff0f 0%, #fff0 100%);
  border: 1px solid #0000;
  border-image: linear-gradient(#fff, #fff0) 1;
  border-radius: 36px;
  width: 464px;
  height: 525px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .div-wrapper {
  width: 396px;
  height: 74px;
  position: absolute;
  top: 489px;
  left: 36px;
}

.landing-page .overlap-group-4 {
  width: 392px;
  height: 74px;
  position: relative;
}

.landing-page .rectangle-2 {
  background-color: #ffffff1f;
  border: 1px solid red;
  border-radius: 40.5px;
  width: 392px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .text-wrapper-8 {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 96px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  line-height: 28.8px;
  position: absolute;
  top: 23px;
  left: 203px;
}

.landing-page .text-wrapper-9 {
  color: #f71d1d;
  letter-spacing: 0;
  text-align: center;
  width: 103px;
  font-family: Archivo, Helvetica;
  font-size: 60px;
  font-style: italic;
  font-weight: 900;
  line-height: 72px;
  position: absolute;
  top: 2px;
  left: 92px;
}

.landing-page .element-2 {
  width: 346px;
  height: 116px;
  position: absolute;
  top: 334px;
  left: 61px;
}

.landing-page .text-wrapper-10 {
  color: #f7f3ef99;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  line-height: 28.8px;
  position: absolute;
  top: 58px;
  left: 0;
}

.landing-page .text-wrapper-11 {
  color: #fff9;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  line-height: 57.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .element-3 {
  width: 346px;
  height: 116px;
  position: absolute;
  top: 156px;
  left: 61px;
}

.landing-page .fuori-da-CROWDMOOVI {
  color: #fff9;
  letter-spacing: 0;
  text-align: center;
  width: 337px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 64px;
}

.landing-page .overlap-wrapper {
  width: 466px;
  height: 525px;
  position: absolute;
  top: 109px;
  left: 488px;
}

.landing-page .overlap-5 {
  background: linear-gradient(#ffffff3d 0%, #fff0 100%);
  border-radius: 36px;
  width: 464px;
  height: 525px;
  position: relative;
}

.landing-page .text-wrapper-12 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  line-height: 28.8px;
  position: absolute;
  top: 58px;
  left: 0;
}

.landing-page .text-wrapper-13 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  line-height: 57.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .senza-GENESIS-NFT {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 337px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 64px;
}

.landing-page .overlap-6 {
  width: 1319px;
  height: 874px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .element-4 {
  width: 466px;
  height: 563px;
  position: absolute;
  top: 109px;
  left: 0;
}

.landing-page .rectangle-3 {
  background: linear-gradient(#672d86 0%, #f7941d00 100%);
  border: 1px solid #0000;
  border-image: linear-gradient(#f7941d, #672d8600) 1;
  border-radius: 36px;
  width: 464px;
  height: 525px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 24px 12px #672d8699;
}

.landing-page .rectangle-4 {
  background: linear-gradient(#f7941d 0%, #672d86 100%);
  border: 1px solid #0000;
  border-image: linear-gradient(#f7941d, #672d8600) 1;
  border-radius: 40.5px;
  width: 392px;
  height: 72px;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: 0 0 24px 12px #672d8699;
}

.landing-page .accesso-anticipato-a {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 264px;
  font-family: Archivo, Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 100;
  line-height: 28.8px;
  position: absolute;
  top: 9px;
  left: 92px;
}

.landing-page .text-wrapper-14 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 67px;
  font-family: Archivo, Helvetica;
  font-size: 60px;
  font-style: italic;
  font-weight: 900;
  line-height: 72px;
  position: absolute;
  top: 0;
  left: 30px;
}

.landing-page .text-wrapper-15 {
  color: #f7941d;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  line-height: 57.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .text-wrapper-16 {
  color: #f7941d;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 0 24px #f7941d;
  width: 337px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 64px;
}

.landing-page .CON-GENESIS-CONVIENE {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 1199px;
  height: 39px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 120px;
}

.landing-page .span {
  color: #f7f3ef;
  font-style: italic;
  font-weight: 100;
}

.landing-page .text-wrapper-17 {
  color: #f7f3ef;
  font-style: italic;
  font-weight: 800;
}

.landing-page .text-wrapper-18 {
  color: #f7941d;
  font-style: italic;
  font-weight: 800;
}

.landing-page .group-3 {
  width: 235px;
  height: 851px;
  position: absolute;
  top: 23px;
  left: 232px;
}

.landing-page .line-2 {
  object-fit: cover;
  width: 235px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .line-3 {
  object-fit: cover;
  width: 1px;
  height: 85px;
  position: absolute;
  top: 1px;
  left: 0;
}

.landing-page .line-4 {
  object-fit: cover;
  width: 1px;
  height: 200px;
  position: absolute;
  top: 650px;
  left: 1px;
}

.landing-page .line-5 {
  object-fit: cover;
  width: 234px;
  height: 1px;
  position: absolute;
  top: 850px;
  left: 1px;
}

.landing-page .overlap-7 {
  width: 1920px;
  height: 1176px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.landing-page .nft {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 1440px;
  height: 808px;
  position: absolute;
  top: 368px;
  left: 237px;
}

.landing-page .buy {
  width: 344px;
  height: 48px;
  position: absolute;
  top: 748px;
  left: 969px;
}

.landing-page .overlap-8 {
  backdrop-filter: blur(4px) brightness();
  background-color: #1817191f;
  border: 1px solid #f7f3ef;
  border-radius: 36px;
  width: 342px;
  height: 48px;
  position: relative;
  box-shadow: 0 4px 60px 10px #00000040;
}

.landing-page .text-wrapper-19 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 26px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 50px;
}

.landing-page .vector-3 {
  width: 10px;
  height: 18px;
  position: absolute;
  top: 14px;
  left: 297px;
}

.landing-page .text-wrapper-20 {
  -webkit-text-stroke: 1px #f7f3ef;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  opacity: .24;
  width: 1920px;
  height: 392px;
  font-family: Helvetica;
  font-size: 360px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .text-wrapper-21 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 1199px;
  height: 39px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 209px;
  left: 357px;
}

.landing-page .text-wrapper-22 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 134px;
  left: 786px;
}

.landing-page .HOW {
  width: 1926px;
  height: 2585px;
  position: absolute;
  top: 2573px;
  left: 0;
}

.landing-page .overlap-9 {
  width: 1920px;
  height: 2586px;
  position: relative;
  top: -1px;
  left: -1px;
}

.landing-page .finance-your {
  width: 1322px;
  height: 405px;
  position: absolute;
  top: 1957px;
  left: 363px;
}

.landing-page .firefly-ragazzo-e {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 708px;
  height: 405px;
  position: absolute;
  top: 0;
  left: 610px;
}

.landing-page .realizza-i-tuoi {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: right;
  width: 462px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 202px;
  left: 0;
}

.landing-page .element-finanza-i-tuoi {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 586px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 106px;
  left: 0;
}

.landing-page .text-wrapper-23 {
  color: #672d86;
  font-style: italic;
}

.landing-page .text-wrapper-24 {
  color: #f7f3ef;
  font-style: italic;
}

.landing-page .text-wrapper-25 {
  color: #f7f3ef;
  font-size: 36px;
  font-style: italic;
}

.landing-page .rectangle-5 {
  background-color: #f7f3ef;
  width: 1px;
  height: 160px;
  position: absolute;
  top: 132px;
  left: 597px;
}

.landing-page .launch-your-campaign {
  width: 1322px;
  height: 404px;
  position: absolute;
  top: 1436px;
  left: 241px;
}

.landing-page .hai-un-idea-ora-di {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 462px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 202px;
  left: 856px;
}

.landing-page .element-lancia-la-tua {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 586px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 106px;
  left: 732px;
}

.landing-page .rectangle-6 {
  background-color: #f7f3ef;
  width: 1px;
  height: 160px;
  position: absolute;
  top: 132px;
  left: 719px;
}

.landing-page .img-2 {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 708px;
  height: 404px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .create-your-nft {
  width: 1322px;
  height: 404px;
  position: absolute;
  top: 907px;
  left: 363px;
}

.landing-page .create-nft {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 708px;
  height: 404px;
  position: absolute;
  top: 0;
  left: 610px;
}

.landing-page .trasforma-la-tua {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: right;
  width: 462px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 194px;
  left: 0;
}

.landing-page .element-crea-il-tuo-NFT {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 464px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 114px;
  left: 122px;
}

.landing-page .text-wrapper-26 {
  color: #f7941d;
  font-style: italic;
}

.landing-page .rectangle-7 {
  background-color: #f7f3ef;
  width: 1px;
  height: 160px;
  position: absolute;
  top: 124px;
  left: 597px;
}

.landing-page .create-your-profile {
  width: 1322px;
  height: 404px;
  position: absolute;
  top: 393px;
  left: 241px;
}

.landing-page .text-wrapper-27 {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 462px;
  height: 88px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 217px;
  left: 856px;
}

.landing-page .element-crea-il-tuo {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 464px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 137px;
  left: 732px;
}

.landing-page .rectangle-8 {
  background-color: #f7f3ef;
  width: 1px;
  height: 160px;
  position: absolute;
  top: 147px;
  left: 719px;
}

.landing-page .funziona-crowdmoovi {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 1199px;
  height: 39px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 209px;
  left: 361px;
}

.landing-page .text-wrapper-28 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  height: 78px;
  font-family: Archivo, Helvetica;
  font-size: 72px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 134px;
  left: 790px;
}

.landing-page .vector-4 {
  width: 490px;
  height: 2237px;
  position: absolute;
  top: 349px;
  left: 751px;
}

.landing-page .WHY {
  width: 1926px;
  height: 1380px;
  position: absolute;
  top: 1068px;
  left: 4px;
}

.landing-page .overlap-10 {
  width: 1920px;
  height: 935px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.landing-page .element-5 {
  width: 468px;
  height: 464px;
  position: absolute;
  top: 444px;
  left: 237px;
}

.landing-page .si-applica-una {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 315px;
  left: 61px;
}

.landing-page .text-wrapper-29 {
  font-style: italic;
}

.landing-page .text-wrapper-30 {
  font-size: 16px;
  font-style: italic;
  line-height: 19.2px;
}

.landing-page .risparmio-per-gli {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 337px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 227px;
  left: 64px;
}

.landing-page .firefly-icona-con {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 342px;
  height: 194px;
  position: absolute;
  top: 3px;
  left: 61px;
}

.landing-page .overlap-11 {
  width: 1920px;
  height: 854px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .element-6 {
  width: 468px;
  height: 500px;
  position: absolute;
  top: 354px;
  left: 724px;
}

.landing-page .text-wrapper-31 {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 352px;
  left: 61px;
}

.landing-page .royalties-community {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 337px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 263px;
  left: 64px;
}

.landing-page .firefly-create-a {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 243px;
  height: 243px;
  position: absolute;
  top: 0;
  left: 110px;
}

.landing-page .element-7 {
  width: 468px;
  height: 542px;
  position: absolute;
  top: 393px;
  left: 1213px;
}

.landing-page .grazie-alle-nft {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 342px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 21.6px;
  position: absolute;
  top: 366px;
  left: 61px;
}

.landing-page .ricompense-per-i {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 337px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 278px;
  left: 64px;
}

.landing-page .firefly-icona-con-2 {
  background-blend-mode: lighten;
  object-fit: cover;
  width: 295px;
  height: 269px;
  position: absolute;
  top: 0;
  left: 84px;
}

.landing-page .mission {
  width: 1203px;
  height: 251px;
  position: absolute;
  top: 1129px;
  left: 356px;
}

.landing-page .overlap-group-5 {
  width: 1199px;
  height: 251px;
  position: relative;
}

.landing-page .group-4 {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 18px;
  left: 159px;
}

.landing-page .rectangle-9 {
  background-color: #f7f3ef;
  width: 1px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .rectangle-10 {
  background-color: #f7f3ef;
  width: 1px;
  height: 60px;
  position: absolute;
  top: -29px;
  left: 29px;
  transform: rotate(90deg);
}

.landing-page .group-5 {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 176px;
  left: 980px;
  transform: rotate(180deg);
}

.landing-page .rectangle-11 {
  background-color: #f7f3ef;
  width: 1px;
  height: 60px;
  position: absolute;
  top: -29px;
  left: 29px;
  transform: rotate(-270deg);
}

.landing-page .la-nostra-mission {
  color: #f7941d;
  letter-spacing: 0;
  text-align: center;
  width: 1199px;
  height: 39px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .il-nostro-obiettivo {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 842px;
  font-family: Archivo, Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  line-height: 28.8px;
  position: absolute;
  top: 48px;
  left: 179px;
}

.landing-page .hero {
  width: 2008px;
  height: 1166px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .overlap-12 {
  width: 1581px;
  height: 1140px;
  position: relative;
  top: 26px;
  left: 339px;
}

.landing-page .png-NB {
  object-fit: cover;
  width: 1395px;
  height: 1140px;
  position: absolute;
  top: 0;
  left: 186px;
}

.landing-page .scopri-di-pi-2 {
  width: 214px;
  height: 120px;
  position: absolute;
  top: 742px;
  left: 519px;
}

.landing-page .SCOPRI-DI-pi {
  color: #f7f3ef;
  letter-spacing: 3.36px;
  text-align: center;
  width: 212px;
  height: 15px;
  font-family: Archivo, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 53px;
  left: 0;
}

.landing-page .overlap-group-6 {
  width: 18px;
  height: 49px;
  position: absolute;
  top: 72px;
  left: 93px;
}

.landing-page .rectangle-12 {
  background-color: #f7f3ef;
  width: 1px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 8px;
}

.landing-page .vector-5 {
  width: 18px;
  height: 10px;
  position: absolute;
  top: 40px;
  left: 0;
}

.landing-page .rectangle-13 {
  background-color: #f7f3ef;
  width: 1px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 101px;
}

.landing-page .first-NFT-DRIVEN {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 711px;
  height: 69px;
  font-family: Archivo, Helvetica;
  font-size: 36px;
  font-weight: 100;
  line-height: 43.2px;
  position: absolute;
  top: 506px;
  left: 23px;
}

.landing-page .text-wrapper-32 {
  font-size: 24px;
  font-style: italic;
  line-height: 28.8px;
}

.landing-page .rectangle-14 {
  background-color: #f7f3ef;
  width: 620px;
  height: 1px;
  position: absolute;
  top: 489px;
  left: 0;
}

.landing-page .text-wrapper-33 {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 1199px;
  height: 104px;
  font-family: Archivo, Helvetica;
  font-size: 96px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 376px;
  left: 23px;
}

.landing-page .text-wrapper-34 {
  color: #f7f3ef;
  letter-spacing: 0;
  width: 342px;
  height: 52px;
  font-family: Archivo, Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 334px;
  left: 23px;
}

.landing-page .lines-on-top {
  width: 1245px;
  height: 8847px;
  position: absolute;
  top: 26px;
  left: 338px;
}

.landing-page .overlap-13 {
  height: 8847px;
  position: relative;
}

.landing-page .rectangle-15, .landing-page .rectangle-16 {
  -webkit-text-stroke: 1px var(--black);
  color: #0000;
  opacity: .24;
  text-align: center;
  text-transform: uppercase;
  background-blend-mode: overlay;
  background-color: #f7f3ef99;
  width: 8847px;
  height: 1px;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  position: absolute;
  top: 4423px;
  transform: rotate(90deg);
}

.landing-page .rectangle-15 {
  left: -4423px;
}

.landing-page .rectangle-16 {
  left: -3179px;
}

.landing-page .rectangle-17 {
  -webkit-text-stroke: 1px #1a1b1c;
  color: #0000;
  opacity: .24;
  text-align: center;
  text-transform: uppercase;
  background-blend-mode: overlay;
  background-color: #f7f3ef99;
  width: 8847px;
  height: 1px;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  position: absolute;
  top: 4423px;
  left: -3802px;
  transform: rotate(90deg);
}

.landing-page .header {
  background-color: #0000;
  width: 1928px;
  height: 96px;
  position: fixed;
  top: 0;
  left: 0;
}

.landing-page .overlap-14 {
  backdrop-filter: blur(24px) brightness();
  background-color: #ffffff03;
  width: 1920px;
  height: 96px;
  position: relative;
}

.landing-page .overlap-15 {
  width: 342px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 240px;
}

.landing-page .rectangle-18 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1a1b1c;
  -webkit-text-stroke-color: var(--black);
  color: #0000;
  opacity: .24;
  text-align: center;
  text-transform:  uppercase;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  position: absolute;
}

.landing-page .crowdmoovi-logo-2 {
  width: 342px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .overlap-16 {
  width: 222px;
  height: 119px;
  position: absolute;
  top: 24px;
  left: 1460px;
}

.landing-page .rectangle-19 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1a1b1c;
  -webkit-text-stroke-color: var(--black);
  color: #0000;
  opacity: .24;
  text-align: center;
  text-transform:  uppercase;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  position: absolute;
}

.landing-page .marketplace-button {
  width: 222px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .marketplace-wrapper {
  border: 1px solid #f7f3ef;
  border-radius: 60px;
  width: 220px;
  height: 48px;
  position: relative;
}

.landing-page .marketplace {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  height: 20px;
  font-family: Archivo, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 24px;
}

.landing-page .overlap-17 {
  width: 245px;
  height: 104px;
  position: absolute;
  top: 39px;
  left: 882px;
}

.landing-page .rectangle-20 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1a1b1c;
  -webkit-text-stroke-color: var(--black);
  color: #0000;
  opacity: .24;
  text-align: center;
  text-transform:  uppercase;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  position: absolute;
}

.landing-page .come-funziona {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: right;
  width: 140px;
  height: 17px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 105px;
}

.landing-page .perch {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: right;
  width: 69px;
  height: 17px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .join-the-club {
  color: #f7f3ef;
  letter-spacing: 0;
  text-align: right;
  width: 125px;
  height: 17px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 39px;
  left: 1311px;
}

.landing-page .genesis-nft {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: right;
  text-fill-color: transparent;
  background: linear-gradient(#f7941d 0%, #672d86 100%) text;
  width: 112px;
  height: 17px;
  font-family: Archivo, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 39px;
  left: 1163px;
  -webkit-background-clip: text !important;
}
/*# sourceMappingURL=index.1954d989.css.map */
