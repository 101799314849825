.landing-page {
  background-color: #181719;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.landing-page .div {
  background-color: #181719;
  height: 8925px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.landing-page .overlap {
  height: 8951px;
  left: 0;
  position: absolute;
  top: -26px;
  width: 2008px;
}

.landing-page .main-bg {
  height: 7629px;
  left: 0;
  position: absolute;
  top: 26px;
  width: 1920px;
}

.landing-page .footer {
  background-color: #0000003d;
  height: 480px;
  left: 0;
  position: absolute;
  top: 8471px;
  width: 1920px;
}

.landing-page .subfooter {
  height: 60px;
  left: 0;
  position: absolute;
  top: 420px;
  width: 1926px;
}

.landing-page .overlap-group {
  background-color: #0000007a;
  height: 60px;
  position: relative;
  width: 1920px;
}

.landing-page .cookies-policy-terms {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 15px;
  font-weight: 100;
  height: 16px;
  left: 1216px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 22px;
  width: 464px;
}

.landing-page .text-wrapper {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 15px;
  font-weight: 100;
  height: 16px;
  left: 728px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 22px;
  width: 464px;
}

.landing-page .p {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 15px;
  font-weight: 100;
  height: 16px;
  left: 240px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
  width: 464px;
}

.landing-page .group {
  height: 134px;
  left: 1216px;
  position: absolute;
  top: 130px;
  width: 466px;
}

.landing-page .rimani-aggiornato {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 464px;
}

.landing-page .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 342px;
}

.landing-page .linkedin {
  height: 48px;
  left: 0;
  position: absolute;
  top: 86px;
  width: 48px;
}

.landing-page .facebook {
  height: 48px;
  left: 98px;
  position: absolute;
  top: 86px;
  width: 48px;
}

.landing-page .instagram {
  height: 48px;
  left: 196px;
  position: absolute;
  top: 86px;
  width: 48px;
}

.landing-page .group-2 {
  height: 217px;
  left: 726px;
  position: absolute;
  top: 130px;
  width: 474px;
}

.landing-page .scopri-di-pi {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  height: 20px;
  left: 2px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 464px;
}

.landing-page .team {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 73px;
  width: 222px;
}

.landing-page .white-paper {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 121px;
  width: 222px;
}

.landing-page .roadmap {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 169px;
  width: 222px;
}

.landing-page .img {
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 342px;
}

.landing-page .crowdmoovi-logo {
  height: 50px;
  left: 240px;
  position: absolute;
  top: 202px;
  width: 342px;
}

.landing-page .CLUB {
  height: 509px;
  left: 2px;
  position: absolute;
  top: 7737px;
  width: 1924px;
}

.landing-page .invest {
  height: 72px;
  left: 614px;
  position: absolute;
  top: 437px;
  width: 686px;
}

.landing-page .overlap-group-2 {
  border-radius: 40.5px;
  height: 72px;
  position: relative;
  width: 684px;
}

.landing-page .text-wrapper-2 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  height: 26px;
  left: 62px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 23px;
  width: 313px;
}

.landing-page .vector {
  height: 18px;
  left: 600px;
  position: absolute;
  top: 27px;
  width: 14px;
}

.landing-page .text-wrapper-3 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  left: 660px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 305px;
  width: 596px;
}

.landing-page .overlap-2 {
  height: 305px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 1916px;
}

.landing-page .text-wrapper-4 {
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
  background-image: linear-gradient(180deg, rgb(247, 148, 29) 0%, rgb(103, 45, 134) 100%);
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 280px;
  font-style: italic;
  font-weight: 800;
  height: 305px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1916px;
}

.landing-page .text-wrapper-5 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(103, 45, 134) 0%, rgb(247, 148, 29) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-style: italic;
  font-weight: 800;
  height: 78px;
  left: 598px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-fill-color: transparent;
  top: 130px;
  width: 714px;
}

.landing-page .text-wrapper-6 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 39px;
  left: 355px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 91px;
  width: 1199px;
}

.landing-page .OUR-NFT {
  height: 2294px;
  left: 4px;
  position: absolute;
  top: 5169px;
  width: 1928px;
}

.landing-page .overlap-3 {
  height: 908px;
  left: 236px;
  position: absolute;
  top: 1386px;
  width: 1442px;
}

.landing-page .overlap-group-wrapper {
  height: 72px;
  left: 488px;
  position: absolute;
  top: 836px;
  width: 466px;
}

.landing-page .overlap-group-3 {
  background: linear-gradient(180deg, rgb(103, 45, 134) 0%, rgb(103, 45, 134) 100%);
  border-radius: 40.5px;
  box-shadow: 0px 0px 24px 12px #672d8699;
  height: 72px;
  position: relative;
  width: 464px;
}

.landing-page .text-wrapper-7 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 39px;
  left: 57px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 17px;
  white-space: nowrap;
}

.landing-page .vector-2 {
  height: 18px;
  left: 407px;
  position: absolute;
  top: 27px;
  width: 10px;
}

.landing-page .element {
  height: 563px;
  left: 976px;
  position: absolute;
  top: 109px;
  width: 466px;
}

.landing-page .overlap-4 {
  height: 563px;
  position: relative;
  width: 464px;
}

.landing-page .rectangle {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0)) 1;
  border-radius: 36px;
  height: 525px;
  left: 0;
  position: absolute;
  top: 0;
  width: 464px;
}

.landing-page .div-wrapper {
  height: 74px;
  left: 36px;
  position: absolute;
  top: 489px;
  width: 396px;
}

.landing-page .overlap-group-4 {
  height: 74px;
  position: relative;
  width: 392px;
}

.landing-page .rectangle-2 {
  background-color: #ffffff1f;
  border: 1px solid;
  border-color: #ff0000;
  border-radius: 40.5px;
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 392px;
}

.landing-page .text-wrapper-8 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  left: 203px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 23px;
  width: 96px;
}

.landing-page .text-wrapper-9 {
  color: #f71d1d;
  font-family: "Archivo", Helvetica;
  font-size: 60px;
  font-style: italic;
  font-weight: 900;
  left: 92px;
  letter-spacing: 0;
  line-height: 72px;
  position: absolute;
  text-align: center;
  top: 2px;
  width: 103px;
}

.landing-page .element-2 {
  height: 116px;
  left: 61px;
  position: absolute;
  top: 334px;
  width: 346px;
}

.landing-page .text-wrapper-10 {
  color: #f7f3ef99;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 58px;
  width: 342px;
}

.landing-page .text-wrapper-11 {
  color: #ffffff99;
  font-family: "Archivo", Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  left: 0;
  letter-spacing: 0;
  line-height: 57.6px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 342px;
}

.landing-page .element-3 {
  height: 116px;
  left: 61px;
  position: absolute;
  top: 156px;
  width: 346px;
}

.landing-page .fuori-da-CROWDMOOVI {
  color: #ffffff99;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 69px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: 337px;
}

.landing-page .overlap-wrapper {
  height: 525px;
  left: 488px;
  position: absolute;
  top: 109px;
  width: 466px;
}

.landing-page .overlap-5 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 36px;
  height: 525px;
  position: relative;
  width: 464px;
}

.landing-page .text-wrapper-12 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 58px;
  width: 342px;
}

.landing-page .text-wrapper-13 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  left: 0;
  letter-spacing: 0;
  line-height: 57.6px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 342px;
}

.landing-page .senza-GENESIS-NFT {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 69px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: 337px;
}

.landing-page .overlap-6 {
  height: 874px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1319px;
}

.landing-page .element-4 {
  height: 563px;
  left: 0;
  position: absolute;
  top: 109px;
  width: 466px;
}

.landing-page .rectangle-3 {
  background: linear-gradient(180deg, rgb(103, 45, 134) 0%, rgba(247, 148, 29, 0) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247, 148, 29), rgba(103, 45, 134, 0)) 1;
  border-radius: 36px;
  box-shadow: 0px 0px 24px 12px #672d8699;
  height: 525px;
  left: 0;
  position: absolute;
  top: 0;
  width: 464px;
}

.landing-page .rectangle-4 {
  background: linear-gradient(180deg, rgb(247, 148, 29) 0%, rgb(103, 45, 134) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247, 148, 29), rgba(103, 45, 134, 0)) 1;
  border-radius: 40.5px;
  box-shadow: 0px 0px 24px 12px #672d8699;
  height: 72px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 392px;
}

.landing-page .accesso-anticipato-a {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 100;
  left: 92px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 9px;
  width: 264px;
}

.landing-page .text-wrapper-14 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 60px;
  font-style: italic;
  font-weight: 900;
  left: 30px;
  letter-spacing: 0;
  line-height: 72px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 67px;
}

.landing-page .text-wrapper-15 {
  color: #f7941d;
  font-family: "Archivo", Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  left: 0;
  letter-spacing: 0;
  line-height: 57.6px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 342px;
}

.landing-page .text-wrapper-16 {
  color: #f7941d;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 69px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-shadow: 0px 0px 24px #f7941d;
  top: 25px;
  width: 337px;
}

.landing-page .CON-GENESIS-CONVIENE {
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-weight: 400;
  height: 39px;
  left: 120px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1199px;
}

.landing-page .span {
  color: #f7f3ef;
  font-style: italic;
  font-weight: 100;
}

.landing-page .text-wrapper-17 {
  color: #f7f3ef;
  font-style: italic;
  font-weight: 800;
}

.landing-page .text-wrapper-18 {
  color: #f7941d;
  font-style: italic;
  font-weight: 800;
}

.landing-page .group-3 {
  height: 851px;
  left: 232px;
  position: absolute;
  top: 23px;
  width: 235px;
}

.landing-page .line-2 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 235px;
}

.landing-page .line-3 {
  height: 85px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 1px;
}

.landing-page .line-4 {
  height: 200px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 650px;
  width: 1px;
}

.landing-page .line-5 {
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 850px;
  width: 234px;
}

.landing-page .overlap-7 {
  height: 1176px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 1920px;
}

.landing-page .nft {
  background-blend-mode: lighten;
  height: 808px;
  left: 237px;
  object-fit: cover;
  position: absolute;
  top: 368px;
  width: 1440px;
}

.landing-page .buy {
  height: 48px;
  left: 969px;
  position: absolute;
  top: 748px;
  width: 344px;
}

.landing-page .overlap-8 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #1817191f;
  border: 1px solid;
  border-color: #f7f3ef;
  border-radius: 36px;
  box-shadow: 0px 4px 60px 10px #00000040;
  height: 48px;
  position: relative;
  width: 342px;
}

.landing-page .text-wrapper-19 {
  color: #ffffff;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  height: 26px;
  left: 50px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
}

.landing-page .vector-3 {
  height: 18px;
  left: 297px;
  position: absolute;
  top: 14px;
  width: 10px;
}

.landing-page .text-wrapper-20 {
  -webkit-text-stroke: 1px #f7f3ef; /* Usa 1px di bordo con il colore specificato */
  color: transparent;
  font-family: Helvetica; /* Usa il font indicato */
  font-size: 360px; /* Imposta la dimensione del font */
  font-style: italic; /* Imposta il font come italic */
  font-weight: 800;
  height: 392px; /* Imposta l'altezza */
  left: 0;
  letter-spacing: 0;
  line-height: normal; /* Usa una line-height normale */
  position: absolute;
  text-align: center;
  text-transform: uppercase; /* Mantieni maiuscolo il testo */
  top: 0;
  width: 1920px; /* Imposta la larghezza */
  opacity: .24; /* Imposta l'opacità */
}

.landing-page .text-wrapper-21 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 39px;
  left: 357px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 209px;
  width: 1199px;
}

.landing-page .text-wrapper-22 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-style: italic;
  font-weight: 800;
  height: 78px;
  left: 786px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 134px;
  width: 342px;
}

.landing-page .HOW {
  height: 2585px;
  left: 0;
  position: absolute;
  top: 2573px;
  width: 1926px;
}

.landing-page .overlap-9 {
  height: 2586px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 1920px;
}

.landing-page .finance-your {
  height: 405px;
  left: 363px;
  position: absolute;
  top: 1957px;
  width: 1322px;
}

.landing-page .firefly-ragazzo-e {
  background-blend-mode: lighten;
  height: 405px;
  left: 610px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 708px;
}

.landing-page .realizza-i-tuoi {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: right;
  top: 202px;
  width: 462px;
}

.landing-page .element-finanza-i-tuoi {
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-weight: 800;
  height: 78px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 106px;
  width: 586px;
}

.landing-page .text-wrapper-23 {
  color: #672d86;
  font-style: italic;
}

.landing-page .text-wrapper-24 {
  color: #f7f3ef;
  font-style: italic;
}

.landing-page .text-wrapper-25 {
  color: #f7f3ef;
  font-size: 36px;
  font-style: italic;
}

.landing-page .rectangle-5 {
  background-color: #f7f3ef;
  height: 160px;
  left: 597px;
  position: absolute;
  top: 132px;
  width: 1px;
}

.landing-page .launch-your-campaign {
  height: 404px;
  left: 241px;
  position: absolute;
  top: 1436px;
  width: 1322px;
}

.landing-page .hai-un-idea-ora-di {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  left: 856px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 202px;
  width: 462px;
}

.landing-page .element-lancia-la-tua {
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-weight: 800;
  height: 78px;
  left: 732px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 106px;
  width: 586px;
}

.landing-page .rectangle-6 {
  background-color: #f7f3ef;
  height: 160px;
  left: 719px;
  position: absolute;
  top: 132px;
  width: 1px;
}

.landing-page .img-2 {
  background-blend-mode: lighten;
  height: 404px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 708px;
}

.landing-page .create-your-nft {
  height: 404px;
  left: 363px;
  position: absolute;
  top: 907px;
  width: 1322px;
}

.landing-page .create-nft {
  background-blend-mode: lighten;
  height: 404px;
  left: 610px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 708px;
}

.landing-page .trasforma-la-tua {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  left: 0;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: right;
  top: 194px;
  width: 462px;
}

.landing-page .element-crea-il-tuo-NFT {
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-weight: 800;
  height: 78px;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 114px;
  width: 464px;
}

.landing-page .text-wrapper-26 {
  color: #f7941d;
  font-style: italic;
}

.landing-page .rectangle-7 {
  background-color: #f7f3ef;
  height: 160px;
  left: 597px;
  position: absolute;
  top: 124px;
  width: 1px;
}

.landing-page .create-your-profile {
  height: 404px;
  left: 241px;
  position: absolute;
  top: 393px;
  width: 1322px;
}

.landing-page .text-wrapper-27 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  height: 88px;
  left: 856px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 217px;
  width: 462px;
}

.landing-page .element-crea-il-tuo {
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-weight: 800;
  height: 78px;
  left: 732px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 137px;
  width: 464px;
}

.landing-page .rectangle-8 {
  background-color: #f7f3ef;
  height: 160px;
  left: 719px;
  position: absolute;
  top: 147px;
  width: 1px;
}

.landing-page .funziona-crowdmoovi {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 39px;
  left: 361px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 209px;
  width: 1199px;
}

.landing-page .text-wrapper-28 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 72px;
  font-style: italic;
  font-weight: 800;
  height: 78px;
  left: 790px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 134px;
  width: 342px;
}

.landing-page .vector-4 {
  height: 2237px;
  left: 751px;
  position: absolute;
  top: 349px;
  width: 490px;
}

.landing-page .WHY {
  height: 1380px;
  left: 4px;
  position: absolute;
  top: 1068px;
  width: 1926px;
}

.landing-page .overlap-10 {
  height: 935px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 1920px;
}

.landing-page .element-5 {
  height: 464px;
  left: 237px;
  position: absolute;
  top: 444px;
  width: 468px;
}

.landing-page .si-applica-una {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-weight: 100;
  left: 61px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: center;
  top: 315px;
  width: 342px;
}

.landing-page .text-wrapper-29 {
  font-style: italic;
}

.landing-page .text-wrapper-30 {
  font-size: 16px;
  font-style: italic;
  line-height: 19.2px;
}

.landing-page .risparmio-per-gli {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 69px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 227px;
  width: 337px;
}

.landing-page .firefly-icona-con {
  background-blend-mode: lighten;
  height: 194px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 342px;
}

.landing-page .overlap-11 {
  height: 854px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.landing-page .element-6 {
  height: 500px;
  left: 724px;
  position: absolute;
  top: 354px;
  width: 468px;
}

.landing-page .text-wrapper-31 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  left: 61px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: center;
  top: 352px;
  width: 342px;
}

.landing-page .royalties-community {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 69px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 263px;
  width: 337px;
}

.landing-page .firefly-create-a {
  background-blend-mode: lighten;
  height: 243px;
  left: 110px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 243px;
}

.landing-page .element-7 {
  height: 542px;
  left: 1213px;
  position: absolute;
  top: 393px;
  width: 468px;
}

.landing-page .grazie-alle-nft {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  left: 61px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  text-align: center;
  top: 366px;
  width: 342px;
}

.landing-page .ricompense-per-i {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 69px;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 278px;
  width: 337px;
}

.landing-page .firefly-icona-con-2 {
  background-blend-mode: lighten;
  height: 269px;
  left: 84px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 295px;
}

.landing-page .mission {
  height: 251px;
  left: 356px;
  position: absolute;
  top: 1129px;
  width: 1203px;
}

.landing-page .overlap-group-5 {
  height: 251px;
  position: relative;
  width: 1199px;
}

.landing-page .group-4 {
  height: 60px;
  left: 159px;
  position: absolute;
  top: 18px;
  width: 60px;
}

.landing-page .rectangle-9 {
  background-color: #f7f3ef;
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.landing-page .rectangle-10 {
  background-color: #f7f3ef;
  height: 60px;
  left: 29px;
  position: absolute;
  top: -29px;
  transform: rotate(90deg);
  width: 1px;
}

.landing-page .group-5 {
  height: 60px;
  left: 980px;
  position: absolute;
  top: 176px;
  transform: rotate(180deg);
  width: 60px;
}

.landing-page .rectangle-11 {
  background-color: #f7f3ef;
  height: 60px;
  left: 29px;
  position: absolute;
  top: -29px;
  transform: rotate(-270deg);
  width: 1px;
}

.landing-page .la-nostra-mission {
  color: #f7941d;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 800;
  height: 39px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1199px;
}

.landing-page .il-nostro-obiettivo {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
  left: 179px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 48px;
  width: 842px;
}

.landing-page .hero {
  height: 1166px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2008px;
}

.landing-page .overlap-12 {
  height: 1140px;
  left: 339px;
  position: relative;
  top: 26px;
  width: 1581px;
}

.landing-page .png-NB {
  height: 1140px;
  left: 186px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1395px;
}

.landing-page .scopri-di-pi-2 {
  height: 120px;
  left: 519px;
  position: absolute;
  top: 742px;
  width: 214px;
}

.landing-page .SCOPRI-DI-pi {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 15px;
  left: 0;
  letter-spacing: 3.36px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 53px;
  width: 212px;
}

.landing-page .overlap-group-6 {
  height: 49px;
  left: 93px;
  position: absolute;
  top: 72px;
  width: 18px;
}

.landing-page .rectangle-12 {
  background-color: #f7f3ef;
  height: 48px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 1px;
}

.landing-page .vector-5 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 18px;
}

.landing-page .rectangle-13 {
  background-color: #f7f3ef;
  height: 48px;
  left: 101px;
  position: absolute;
  top: 0;
  width: 1px;
}

.landing-page .first-NFT-DRIVEN {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 36px;
  font-weight: 100;
  height: 69px;
  left: 23px;
  letter-spacing: 0;
  line-height: 43.2px;
  position: absolute;
  top: 506px;
  width: 711px;
}

.landing-page .text-wrapper-32 {
  font-size: 24px;
  font-style: italic;
  line-height: 28.8px;
}

.landing-page .rectangle-14 {
  background-color: #f7f3ef;
  height: 1px;
  left: 0;
  position: absolute;
  top: 489px;
  width: 620px;
}

.landing-page .text-wrapper-33 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 96px;
  font-style: italic;
  font-weight: 800;
  height: 104px;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 376px;
  width: 1199px;
}

.landing-page .text-wrapper-34 {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 800;
  height: 52px;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 334px;
  width: 342px;
}

.landing-page .lines-on-top {
  height: 8847px;
  left: 338px;
  position: absolute;
  top: 26px;
  width: 1245px;
}

.landing-page .overlap-13 {
  height: 8847px;
  position: relative;
}

.landing-page .rectangle-15, 
.landing-page .rectangle-16 {
  -webkit-text-stroke: 1px var(--black); /* Combina larghezza e colore del bordo */
  color: transparent;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  opacity: .24;
  position: absolute;
  text-align: center;
  text-transform: uppercase;

  /* Proprietà specifiche per rectangle-15 e rectangle-16 */
  background-blend-mode: overlay;
  background-color: #f7f3ef99;
  height: 1px;
  top: 4423px;
  transform: rotate(90deg);
  width: 8847px;
}

.landing-page .rectangle-15 {
  left: -4423px;
}

.landing-page .rectangle-16 {
  left: -3179px;
}

.landing-page .rectangle-17 { 
  -webkit-text-stroke: 1px #1a1b1c; /* Combina larghezza e colore del bordo */
  color: transparent;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  opacity: .24;
  position: absolute;
  text-align: center;
  text-transform: uppercase;

  /* Aggiunta delle nuove proprietà */
  background-blend-mode: overlay;
  background-color: #f7f3ef99;
  height: 1px;
  left: -3802px;
  top: 4423px;
  transform: rotate(90deg);
  width: 8847px;
}

.landing-page .header {
  background-color: transparent;
  height: 96px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1928px;
}

.landing-page .overlap-14 {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  backdrop-filter: blur(24px) brightness(100%);
  background-color: #ffffff03;
  height: 96px;
  position: relative;
  width: 1920px;
}

.landing-page .overlap-15 {
  height: 119px;
  left: 240px;
  position: absolute;
  top: 24px;
  width: 342px;
}

.landing-page .rectangle-18 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1a1b1c;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  opacity: .24;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
}

.landing-page .crowdmoovi-logo-2 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 342px;
}

.landing-page .overlap-16 {
  height: 119px;
  left: 1460px;
  position: absolute;
  top: 24px;
  width: 222px;
}

.landing-page .rectangle-19 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1a1b1c;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  opacity: .24;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
}

.landing-page .marketplace-button {
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 222px;
}

.landing-page .marketplace-wrapper {
  border: 1px solid;
  border-color: #f7f3ef;
  border-radius: 60px;
  height: 48px;
  position: relative;
  width: 220px;
}

.landing-page .marketplace {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 20px;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
  width: 170px;
}

.landing-page .overlap-17 {
  height: 104px;
  left: 882px;
  position: absolute;
  top: 39px;
  width: 245px;
}

.landing-page .rectangle-20 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1a1b1c;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
  font-size: 23vw;
  font-weight: 800;
  line-height: 23vw;
  opacity: .24;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
}

.landing-page .come-funziona {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 105px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 0;
  width: 140px;
}

.landing-page .perch {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 0;
  width: 69px;
}

.landing-page .join-the-club {
  color: #f7f3ef;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 1311px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 39px;
  width: 125px;
}

.landing-page .genesis-nft {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(247, 148, 29) 0%, rgb(103, 45, 134) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Archivo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 1163px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  text-fill-color: transparent;
  top: 39px;
  width: 112px;
}
